var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-card',[_c('statistic-card-with-area-chart',{attrs:{"icon":"CreditCardIcon","color":"info","statistic-title":"DEX","statistic":"36.4k"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-card',[_c('statistic-card-with-area-chart',{attrs:{"icon":"CreditCardIcon","color":"warning","statistic-title":"CEX","statistic":"36.4k"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-card',[_c('statistic-card-with-area-chart',{attrs:{"icon":"CreditCardIcon","color":"warning","statistic-title":"CEX","statistic":"36.4k"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-card',[_c('vue-good-table',{staticClass:"table overflow-scroll",attrs:{"columns":_vm.columns,"rows":_vm.users.value,"rtl":_vm.direction,"line-numbers":"","search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
        },"pagination-options":{
          enabled: true,
          perPage: _vm.users.limit,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
        if ( ref === void 0 ) ref = {};
        var column = ref.column;
        var row = ref.row;
        var formattedRow = ref.formattedRow;
return [_c('TableRow',{attrs:{"row":row,"column":column,"formatted-row":formattedRow}}),(column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.edit(row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteUser(row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }