<template>
  <div>
    <b-row>
      <b-col>
        <UserStatView />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <div>
            <!-- User Info: Input Fields -->
            <validation-observer mode="eager" ref="validator">
              <b-form>
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Name" label-for="name">
                      <validation-provider #default="{ errors }" name="name" rules="required">
                        <b-form-input id="name" v-model="form.name" :state="errors.length ? false : null" />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group label="Email" label-for="email">
                      <validation-provider #default="{ errors }" name="email" rules="required|email">
                        <b-form-input
                          id="email"
                          v-model="form.email"
                          type="email"
                          :state="errors.length ? false : null"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group label="Phone" label-for="phone">
                      <validation-provider #default="{ errors }" name="phone" rules="required">
                        <b-form-input id="phone" v-model="form.phone" type="phone" />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Role -->
                  <b-col cols="12" md="4">
                    <b-form-group label="User Role" label-for="user-role">
                      <validation-provider #default="{ errors }" name="user-role" rules="required">
                        <v-select
                          multiple
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :reduce="val => val.name"
                          input-id="user-role"
                          v-model="form.roles"
                          label="name"
                          :options="roles"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
                  Create user
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import UserStatView from '@/views/apps/users/UserStatView.vue'

// setInteractionMode('lazy')
export default {
  name: 'UsersCreate',
  components: {
    UserStatView,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    vSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        roles: '',
      },
    }
  },

  computed: {
    ...mapGetters({ roles: 'roles/roles' }),
  },

  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      createUser: 'users/createUser',
      fetchUserById: 'users/fetchUserById',
    }),

    initState() {
      this.fetchRoles()

      this.registerValidator(this.$refs.validator)
    },

    save() {
      if (this.requestInProgress) return

      return this.waitRequest(() => {
        return this.createUser(this.form)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'User created',
                message: 'some message',
                icon: 'BellIcon',
              },
            })

            this.$router.push({ name: 'users' })
          })
          .catch(this.checkErrors)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
