<template>
  <b-row>
    <b-col md="3">
      <b-card>
        <statistic-card-with-area-chart icon="CreditCardIcon" color="info" statistic-title="DEX" statistic="36.4k" />
      </b-card>
    </b-col>

    <b-col md="3">
      <b-card>
        <statistic-card-with-area-chart icon="CreditCardIcon" color="warning" statistic-title="CEX" statistic="36.4k" />
      </b-card>
    </b-col>
    <b-col md="3">
      <b-card>
        <statistic-card-with-area-chart icon="CreditCardIcon" color="warning" statistic-title="CEX" statistic="36.4k" />
      </b-card>
    </b-col>

    <b-col md="6">
      <b-card>
        <vue-good-table
          class="table overflow-scroll"
          :columns="columns"
          :rows="users.value"
          :rtl="direction"
          line-numbers
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: users.limit,
          }"
        >
          <template #table-row="{ column, row, formattedRow} = {}">
            <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

            <!-- Column: Action -->
            <span v-if="column.field === 'action'">
              <span>
                <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                  <template v-slot:button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                  </template>
                  <b-dropdown-item @click="edit(row.id)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteUser(row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
        </vue-good-table>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@/views/apps/analytics-dashboard/charts/StatisticCardWithAreaChart.vue'
import TableRow from '@/components/TableRow.vue'
import { VueGoodTable } from 'vue-good-table'
import {columns} from "@/views/apps/users/config/tableConfig";

export default {
  name: 'UserStatView',
  components: {
    BPagination,
    BDropdownItem,
    BFormSelect,
    BDropdown,
    TableRow,
    StatisticCardWithAreaChart,
    BRow,
    BCol,
    BCard,
    VueGoodTable,
  },
  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],
      searchTerm: '',
      dateTo: '',
      dateFrom: '',
    }
  },
}
</script>